:root {
  --g81f: #81ff00;
  --fogra29: #0e1c26;
  --gunmetal_dark: #13232c;
  --gunmetal: #1c3139;
  --gunmetal_light: #253e45;
}

a {
  color: var(--g81f);
  text-decoration-line: none;
}

a:hover {
  color: var(--g81f);
  text-decoration-line: none;
  font-style: italic;
}

.App {
  text-align: center;
  cursor: url('https://i.imgur.com/BlEmY0s.png'), url('https://i.imgur.com/VtWxKwC.png'), auto;
}

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

.App-header {
  background-color: var(--fogra29);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #81ff00;
}

.jetbrains, .jb {
  font-family: 'JetBrains Mono', monospace;
}

.blaue {
  background: var(--gunmetal_dark);
}

.txt-m {
  font-size: medium;
}

.txt-s {
  font-size: small;
}

.g81f {
  color: var(--g81f);
}

.gmlight {
  color: var(--gunmetal_light);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: var(--fogra29); 
}

::-webkit-scrollbar-thumb {
  background: var(--gunmetal_dark);
  border-radius: 10px 0px 0px 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--g81f); 
}

/* Blog Articles */
.acard {
  min-height: 210px;
  min-width: 400px;
  max-width: 400px;
  padding: 0px;
  user-select: none;
}
.acard:hover {
  padding: 34px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.acard:hover > :first-child > :first-child {
  padding-bottom: 20px;
}

@keyframes a_ah_appear {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}


.acard .ahov {
  display: none;
  opacity: 0%;
}
.acard:hover .ahov {
  animation: a_ah_appear 1s linear 0s 1 normal none;
  display: block;
  opacity: 100%;
}

.acover {
  animation: a_ah_appear 0.5s linear 0s 1 normal none;
  opacity: 100%;
}
.acard:hover .acover {
  display: none;
  opacity: 0%;
}

/* Project */
.pcard {
  min-height: 200px;
  min-width: 400px;
  padding: 40px;
  user-select: none;
}

@keyframes a_fff_g81f {
    from {
      color: #fff;
    }
    to {
      color: var(--g81f);
    }
}

.pcard:hover .ptitle,
.acard:hover .ptitle {
  animation: a_fff_g81f 0.5s linear 0s 1 normal none;
  color: var(--g81f);

}

@keyframes a_botbordload {
  from {
    border-bottom: 0px solid #000000;
    border-radius: 0px;
  }
  to {
    border-bottom: 3px solid var(--g81f);
    border-radius: 0px 0px 15px 15px;
  }
}

.pcard:hover,
.acard:hover {
  animation: a_botbordload 0.3s linear 0s 1 normal none;
  border-bottom: 3px solid var(--g81f);
  border-radius: 0px 0px 15px 15px;
}

.pcard:hover,
.acard:hover {
  background-image: url("https://i.imgur.com/wv7nQCU.gif");
  background-color: var(--fogra29);
  background-repeat: no-repeat;
  background-blend-mode: lighten;
  background-size: cover;
  background-position: bottom;
}

.typeico {
  width: auto;
  height: 30px;
  padding: 5px;
  padding-bottom: 8px;
}

.urlico {
  width: auto;
  height: 30px;
  padding: 5px;
  padding-bottom: 8px;
  filter: grayscale(90%) brightness(70%) hue-rotate(120deg);
}

.urlico:hover {
  filter: grayscale(0%) brightness(100%) hue-rotate(0deg);
}

@keyframes a_urlico_load {
  0% {
    filter: grayscale(90%) brightness(70%);
  }
  20%{
    filter: grayscale(0%) brightness(100%);
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(20deg);
  }
  40%{
    transform: rotate(0deg);
  }
  100% {
    filter: grayscale(60%) brightness(70%);
  }
}

.pcard:hover .urlico {
  animation: a_urlico_load 2s linear 0s 1 normal none;
}

.pbrda {
  border: #81ff0070;
  border-width: 1px;
  border-style: groove;
}

.c-inp {
  width: 400px;
  max-width: 80vw;
  height: auto;
  resize: none;
  background-color: var(--fogra29);
  border-width: 0px;
  border-bottom: 1px solid var(--gunmetal_light);
  color: #fff;
  scrollbar-color: var(--g81f);
}

.c-inp:focus-visible {
  animation: a_botbordload 0.4s linear 0s 1 normal none;
  border-bottom: 3px solid var(--g81f);
  border-radius: 0px 0px 15px 15px;
  outline: none;
}

.btn-owg {
  width: 400px;
  max-width: 80vw;
  border-radius: 0px;
  border-color: var(--gunmetal_light);
  color: white;
}

form:valid .btn-owg {
  border-color: white;
}

@keyframes a_btn-owg-h {
  from {
    border-color: #000;
    background-color: #000;
    color: var(--g81f);
  }
  to {
    border-color: var(--g81f);
    background-color: var(--g81f);
    color: #000;
  }
}

form:valid .btn-owg:hover {
  animation: a_btn-owg-h 0.5s linear 0s 1 normal none;
  box-shadow: none !important;
  border-color: var(--g81f);
  background-color: var(--g81f);
}

.btn-owg:focus {
  box-shadow: none !important;
  border-color: var(--g81f);
}

form:invalid .btn-owg {
  display: none;
}

form:invalid .btn-owg:focus {
  box-shadow: none !important;
  border-color: red;
  color: red;
}

.c-inp:-webkit-autofill {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px var(--fogra29) inset;
  box-shadow: 0 0 0px 1000px var(--fogra29) inset;
}

.btn-rd {
  max-width: 80vw;
  border-radius: 0px;
  border-color: var(--gunmetal_light);
  background-color: var(--gunmetal_light);
  color: white;
}

.btn-rd:focus {
  box-shadow: none !important;
  border-color: var(--g81f);
}

@keyframes a_btn-rd-h {
  from {
    border-color: var(--gunmetal_light);
    background-color: var(--gunmetal_light);
    color: var(--g81f);
  }
  to {
    border-color: var(--g81f);
    background-color: var(--g81f);
    color: #000;
  }
}

.btn-rd:hover {
  animation: a_btn-rd-h 0.5s linear 0s 1 normal none;
  box-shadow: none !important;
  border-color: var(--g81f);
  background-color: var(--g81f);
}

.btn-tab {
  max-width: 80vw;
  border-radius: 0px;
  border-color: var(--gunmetal_light);
  background-color: var(--gunmetal_light);
  color: white;
}